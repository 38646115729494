import React from 'react';
import LabelText from '../../components/LabelText';
import StatsBox from '../../components/StatsBox';
import OrganizationIcon from '../../svg/OrganizationIcon';
import TechIcon from '../../svg/TechIcon';
import ProductivityIcon from '../../svg/ProductivityIcon';
import LangProvider from '../../lang/lang-provider';
import PlusIcon from '../../svg/PlusIcon';
const _ = LangProvider.getConstants();

const Stats = () => (
  <div className="container mx-auto text-center">
    <LabelText className="mb-8 text-xl font-bold text-white text-center">
      {_.stats.title}
    </LabelText>
    <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
      <div className="w-full sm:w-1/3 pt-4 pb-4 pl-4 pr-4">
        <StatsBox
          primaryImage={<OrganizationIcon />}
          secondaryText={
            <span className="inline-flex items-center">
              <PlusIcon />
              <p className="ml-2">{_.stats.item1_title}</p>
            </span>
          }
          thirdText={
            <span className="inline-flex items-center">
              <p className="font-light text-white">{_.stats.item1_description}</p>
            </span>
          }
        />
      </div>
      <div className="w-full sm:w-1/3 pt-4 pb-4 pl-4 pr-4">
        <StatsBox
          primaryImage={<TechIcon />}
          secondaryText={
            <span className="inline-flex items-center">
              <PlusIcon />
              <p className="ml-2">{_.stats.item2_title}</p>
            </span>
          }
          thirdText={
            <span className="inline-flex items-center">
              <p className="font-light text-white">{_.stats.item2_description}</p>
            </span>
          }
        />
      </div>
      <div className="w-full sm:w-1/3 pt-4 pb-4 pl-4 pr-4">
        <StatsBox
          primaryImage={<ProductivityIcon />}
          secondaryText={
            <span className="inline-flex items-center">
              <PlusIcon />
              <p className="ml-2">{_.stats.item3_title}</p>
            </span>
          }
          thirdText={
            <span className="inline-flex items-center">
              <p className="font-light text-white">{_.stats.item3_description}</p>
            </span>
          }
        />
      </div>
    </div>
  </div>
);

export default Stats;

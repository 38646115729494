import React from 'react';

const PlusIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.4115 8.55894H15.4409V1.58831C15.4409 0.71118 14.7297 0 13.8526 0H10.1472C9.27012 0 8.55894 0.711133 8.55894 1.58831V8.55866H1.58831C0.711133 8.55866 0 9.26979 0 10.1469V13.8528C0 14.73 0.711133 15.4411 1.58831 15.4411H8.55894V22.4115C8.55894 23.2886 9.27007 23.9997 10.1472 23.9997H13.8528C14.73 23.9997 15.4412 23.2886 15.4412 22.4115V15.4411H22.4118C23.2889 15.4411 24 14.73 24 13.8528V10.1469C23.9998 9.26979 23.2889 8.55894 22.4115 8.55894Z"
      fill="#E74D3C"
    />
  </svg>
);

export default PlusIcon;

import React from 'react';

const TechIntegration = () => (
  <div>
      <img
        className="w-32"
        src={require('../assets/tech-integration.png')}
        alt="logo"
      />
  </div>
);

export default TechIntegration;

import React from 'react';

const OrganizationIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.94866 13.3329C10.6568 13.8731 11.6683 13.7372 12.2087 13.0295C16.9647 6.79822 24.1782 3.22478 31.9998 3.22478C39.8214 3.22478 47.0348 6.79844 51.7909 13.0295C52.1083 13.4452 52.5882 13.6635 53.0739 13.6635C53.4148 13.6635 53.7589 13.5557 54.0509 13.3329C54.7586 12.7925 54.8943 11.7807 54.3543 11.0729C48.9828 4.03588 40.835 0 31.9998 0C23.1645 0 15.0167 4.03588 9.64547 11.0729C9.10524 11.7807 9.24099 12.7925 9.94866 13.3329Z"
      fill="#E74D3C"
    />
    <path
      d="M49.1392 21.9337C49.1392 24.7103 51.3983 26.9694 54.1749 26.9694C56.9515 26.9694 59.2106 24.7103 59.2106 21.9337C59.2106 19.1571 56.9515 16.8979 54.1749 16.8979C51.3983 16.8979 49.1392 19.1569 49.1392 21.9337V21.9337Z"
      fill="#E74D3C"
    />
    <path
      d="M59.3285 28.6407C59.3169 28.6368 58.0481 28.2474 58.0481 28.2474C57.5887 28.1071 57.0896 28.1935 56.7049 28.4814L54.1749 30.3758L51.6448 28.4814C51.26 28.1933 50.7607 28.1069 50.3017 28.2474C50.3017 28.2474 49.0329 28.6368 49.0213 28.6407C46.9797 29.3214 45.6082 31.2244 45.6082 33.3762V40.9892C45.6082 41.2829 45.6939 41.5702 45.8555 41.8154L48.4086 45.7009V55.9047C48.4086 56.7358 49.0823 57.4094 49.9134 57.4094H58.4366C59.2677 57.4094 59.9414 56.7358 59.9414 55.9047V45.7009L62.4945 41.8154C62.6559 41.57 62.7418 41.2827 62.7418 40.9892V33.3762C62.7418 31.2244 61.37 29.3214 59.3285 28.6407V28.6407Z"
      fill="#E74D3C"
    />
    <path
      d="M9.82478 26.9694C12.6013 26.9694 14.8605 24.7103 14.8605 21.9337C14.8605 19.1571 12.6013 16.8979 9.82478 16.8979C7.04821 16.8979 4.78882 19.1569 4.78882 21.9337C4.78882 24.7105 7.048 26.9694 9.82478 26.9694V26.9694Z"
      fill="#E74D3C"
    />
    <path
      d="M14.9784 28.6407C14.9668 28.6368 13.698 28.2474 13.698 28.2474C13.2383 28.1071 12.7397 28.1935 12.3548 28.4814L9.82479 30.3758L7.29474 28.4814C6.9101 28.1933 6.4106 28.1069 5.95161 28.2474C5.95161 28.2474 4.68281 28.6368 4.67117 28.6407C2.62964 29.3214 1.25806 31.2244 1.25806 33.3762V40.9892C1.25806 41.2829 1.34382 41.5702 1.50544 41.8154L4.05854 45.7009V55.9047C4.05854 56.7358 4.73216 57.4094 5.5633 57.4094H14.0863C14.9174 57.4094 15.591 56.7358 15.591 55.9047V45.7009L18.1441 41.8154C18.3055 41.57 18.3915 41.2827 18.3915 40.9892V33.3762C18.3915 31.2244 17.0197 29.3214 14.9784 28.6407Z"
      fill="#E74D3C"
    />
    <path
      d="M38.4951 14.8366C38.4951 11.2551 35.5813 8.34131 31.9999 8.34131C28.4185 8.34131 25.5046 11.2551 25.5046 14.8366C25.5046 18.4182 28.4185 21.3318 31.9999 21.3318C35.5813 21.3318 38.4951 18.4182 38.4951 14.8366Z"
      fill="#E74D3C"
    />
    <path
      d="M39.134 24.4798L39.1232 24.4764L37.2694 23.9075C36.977 23.8176 36.6645 23.9723 36.5593 24.2609L32.6612 34.9566C32.4362 35.5736 31.5637 35.5736 31.3387 34.9566L27.4405 24.2609C27.3556 24.0279 27.1358 23.8818 26.9001 23.8818C26.8443 23.8818 24.8788 24.4755 24.8788 24.4755C22.5106 25.2646 20.9285 27.46 20.9285 29.9424V41.2569C20.9285 41.3691 20.9614 41.479 21.0231 41.573L25.0906 47.7627V63.4244C25.0906 63.7424 25.3483 64.0001 25.6664 64.0001H38.3335C38.6515 64.0001 38.9092 63.7424 38.9092 63.4244V47.7627L42.9768 41.573C43.0384 41.479 43.0714 41.3691 43.0714 41.2569V29.9127C43.0712 27.4411 41.4828 25.2493 39.134 24.4798V24.4798Z"
      fill="#E74D3C"
    />
    <path
      d="M33.5229 24.2545C33.362 24.0791 33.1264 23.9907 32.8881 23.9907H31.1114C30.8733 23.9907 30.6378 24.0791 30.4766 24.2545C30.2272 24.5262 30.191 24.9186 30.368 25.2253L31.3176 26.657L30.8731 30.4075L31.7486 32.7365C31.8339 32.9707 32.1651 32.9707 32.2507 32.7365L33.1262 30.4075L32.6817 26.657L33.6313 25.2253C33.8084 24.9186 33.7722 24.5262 33.5229 24.2545V24.2545Z"
      fill="#E74D3C"
    />
  </svg>
);

export default OrganizationIcon;

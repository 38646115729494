import React from 'react';
import LangProvider from '../../lang/lang-provider';

const _ = LangProvider.getConstants();

const Footer = () => (
  <footer className="footer-container text-gray-800 p-4">
    <div className="flex flex-col xs:flex-wrap md:flex-row justify-center text-center">
      <div className="flex-1 mt-10 px-3">
        <h2 className="text-lg font-semibold text-white">{_.footer.aboutUs_title}</h2>
        <p className="mt-5 xl:pl-4 xl:pr-4 text-gray-400">{_.footer.aboutUs_description}</p>
        <p className="mt-5 font-bold text-primary">{_.footer.aboutUs_lema}</p>
      </div>
      <div className="flex-1 mt-10 px-3">
        <h2 className="text-lg font-semibold text-white">{_.footer.dataSafe.title}</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="/privacy" target="_blank" className="text-white hover:text-primary">{_.footer.dataSafe.privacy}</a>
          </li>
        </ul>
      </div>
      <div className="flex-1 mt-10 px-3">
        <h2 className="text-lg font-semibold text-white">{_.footer.socialNetworks}</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="https://www.facebook.com/VIACCE" target="_blank"
               className="text-white hover:text-primary">Facebook</a>
          </li>
          <li>
            <a href="https://twitter.com/viacce" target="_blank" className="text-white hover:text-primary">Twitter</a>
          </li>
          <li>
            <a href="https://www.instagram.com/_viacce/" target="_blank"
               className="text-white hover:text-primary">Instagram</a>
          </li>
        </ul>
      </div>
    </div>
    <h3 className="text-center mt-10 font-bold text-white">©VIACCE | Business &
      Technology {new Date().getFullYear()}</h3>
  </footer>
);

export default Footer;

import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import LogoIcon from '../../svg/LogoIcon';
import ShoppingCartIcon from '../../svg/ShoppingCartIcon';
import SuitcaseIcon from '../../svg/SuitcaseIcon';
import BookIcon from '../../svg/BookIcon';
import LangProvider from '../../lang/lang-provider';
const _ = LangProvider.getConstants();

const Header = () => (
  <header className="sticky top-0 bg-white z-30">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex items-center text-2xl">
        <div className="w-40 mr-3">
          <AnchorLink href="#home">
            <LogoIcon />
          </AnchorLink>
        </div>
      </div>
      <div className="flex mt-4 sm:mt-0 text-lg">
        <AnchorLink className="px-4 font-bold" href="#services">
          <p className="text-primary-darker hover:text-primary inline-flex items-center">
            <span className="ml-2">{_.header.services}</span>
          </p>
        </AnchorLink>
        <AnchorLink className="px-4 font-bold" href="#testimonials">
          <p className="text-primary-darker hover:text-primary inline-flex items-center">
            <span className="ml-2">{_.header.portfolio}</span>
          </p>
        </AnchorLink>
        <a className="px-4 font-bold" href="https://medium.com/viacce" target="_blank">
          <p className="text-primary-darker hover:text-primary inline-flex items-center">
            <span className="ml-2">{_.header.blog}</span>
          </p>
        </a>
      </div>
    </div>
  </header>
);

export default Header;

import React from 'react';

const Goal = () => (
  <div>
      <img
        className="w-32"
        src={require('../assets/goal.png')}
        alt="logo"
      />
  </div>
);

export default Goal;

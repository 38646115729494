import React from 'react';

const StatsBox = ({ primaryImage, secondaryText, thirdText }) => (
  <>
    <div className="flex justify-center mb-4">{primaryImage}</div>
    <p className="font-semibold text-white">{secondaryText}</p>
    <p className="font-light text-white">{thirdText}</p>
  </>
);

export default StatsBox;

import React from 'react';
import Card from '../../components/Card';
import BusinessProcess from '../../svg/BusinessProcess';
import TechIntegration from '../../svg/TechIntegration';
import Goal from '../../svg/Goal';
import LangProvider from '../../lang/lang-provider';
const _ = LangProvider.getConstants();

const OurValue = () => (
  <div className="mx-auto text-center our-value-container pl-8 pr-8 pt-16 pb-16">
    <h2 className="text-3xl sm:text-1xl md:text-3xl lg:text-5xl font-semibold">
      {_.ourValue.title}
    </h2>
    <div className="flex flex-col sm:flex-wrap md:flex-row sm:-mx-3 mt-12">
      <div
        className="flex-1 px-1"
        data-sal="flip-left"
        data-sal-delay="100"
        data-sal-duration="700"
        data-sal-easing="ease"
      >
        <Card className="background-linear-gradient-blue-dark text-white ml-4 mr-4">
          <div className="mt-5 mb-5 flex justify-center">
            <BusinessProcess />
          </div>
          <p className="font-semibold text-2xl">{_.ourValue.titleProcessManage}</p>
          <p className="mt-4">
            {_.ourValue.subtitleProcessManage}
          </p>
        </Card>
      </div>
      <div className="flex flex-col justify-center">
        <span className="text-6xl font-bold text-primary">+</span>
      </div>
      <div
        className="flex-1 px-1"
        data-sal="flip-left"
        data-sal-delay="400"
        data-sal-duration="700"
        data-sal-easing="ease"
      >
        <Card className="background-linear-gradient-blue-dark text-white ml-4 mr-4">
          <div className="mt-5 mb-5 flex justify-center">
            <TechIntegration />
          </div>
          <p className="font-semibold text-2xl">{_.ourValue.titleTechIntegration}</p>
          <p className="mt-4">
            {_.ourValue.subtitleTechIntegration}
          </p>
        </Card>
      </div>
      <div className="flex flex-col justify-center">
        <span className="text-6xl font-bold text-primary">=</span>
      </div>
      <div
        className="flex-1 px-1"
        data-sal="flip-left"
        data-sal-delay="1000"
        data-sal-duration="700"
        data-sal-easing="easeOutQuart"
      >
        <Card className="background-linear-gradient-orange text-white ml-4 mr-4">
          <div className=" mt-5 mb-5 flex justify-center">
            <Goal />
          </div>
          <p className="font-semibold text-2xl">{_.ourValue.titleCompanyTransformation}</p>
          <p className="mt-4">
            {_.ourValue.subtitleCompanyTransformation}
          </p>
        </Card>
      </div>
    </div>
  </div>
);

export default OurValue;

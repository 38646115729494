import React from 'react';

const BusinessProcess = () => (
  <div>
      <img
        className="w-32"
        src={require('../assets/business-process.png')}
        alt="logo"
      />
  </div>
);

export default BusinessProcess;

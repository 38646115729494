import React from 'react';
import 'react-alice-carousel/lib/alice-carousel.css';
import Main from './components_index/Main';
import OurValue from './components_index/OurValue';
import Services from './components_index/Services';
import Stats from './components_index/Stats';
import Portfolio from './components_index/Portfolio';
import Contact from './components_index/Contact';
import Layout from '../components/layout/Layout';
import { Helmet } from 'react-helmet';
import favicon from '../assets/favicon.ico';

export default () => (
  <Layout>
    <Helmet>
      <title>VIACCE | Business & Technology</title>
      <link rel="icon" href={favicon} />
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      <meta
        content="Empresa de negocios y tecnología | Outsourcing. Somos una empresa que busca transformar la forma habitual de desarrollar negocios, generando valor a tu empresa, trascendiendo la tecnología que implementas y creando la mejor experiencia para tus clientes."
        name="description"
      />
      <meta
        content="Software, Business, Development, Processes, Strategies, Customer Experience, Mystery shopper, Services, Outsourcing, IT, Consulting"
        name="keywords"
      />
      <meta content="#001847" name="theme-color" />
      <meta content="#001847" name="msapplication-navbutton-color" />
      <meta content="#001847" name="apple-mobile-web-app-status-bar-style" />
      <link href="https://viacce.com/" rel="canonical" />
    </Helmet>
    <section id="home" className="pt-20">
      <Main />
    </section>
    <section id="features" className="py-20 lg:pb-40 lg:pt-48">
      <OurValue />
    </section>
    <section id="services">
      <Services />
    </section>
    <section id="stats" className="py-20 mt-12 pt-24 pb-24 background-linear-gradient-blue">
      <Stats />
    </section>
    <section id="testimonials" className="py-20 lg:py-40">
      <Portfolio />
    </section>
    <section
      id="contact"
      className="mx-auto py-24 background-linear-gradient-blue text-center"
    >
      <Contact />
    </section>
  </Layout>
);
